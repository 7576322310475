<template>
    <div class="page-1">
        <div class="box">
            <div class="content">
                <div class="logo"></div>
                <div class="nav">
                    <div class="item " v-for="item in navList" :key="item.name" :class="navName == item.name ? 'active' : ''" @click="clickNav(item)">
                        <div>{{ item.name }}</div>
                        <div></div>
                    </div>
                </div>
                <!-- <div class="login" v-show="!token" @click="login">会员登录 | 注册</div> -->
                <div class="login" style="background:none" ></div>
            </div>
        </div>
        <div class="bj">
            <div class="search_box">
                <div class="tag">
                    <div class="item" v-for="item in  searchTag " :key="item.id" :class="tagName == item.id ? 'active' : ''" @click="clickTag(item.id)">
                        <div>{{ item.name }}</div>
                        <div></div>
                    </div>
                </div>
                <div class="search">
                    <img src="@/assets/image/search.png">
                    <el-input class="input" placeholder="请输入产品或业务关键词进行搜索" v-model="search">
                    </el-input>
                    <div class="btn" @click="clickSearch()">搜索</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            navIndex: 0,
            tagName: 0,
            search: "",
            token: "",
            navList: [

            ],
            searchTag: [
                {
                    name: "找项目",
                    id: 0
                },
                {
                    name: "招标公告",
                    id: 1
                },
				{
				    name: "变更公告",
				    id: 3
				},
                {
                    name: "结果公告",
                    id: 2
                },
                // {
                //     name: "找预审公告",
                //     id: 4
                // },
				],//招标公告，变更公告、结果公告
            data: []
        }
    },
    watch: {
        // '$route.query': {
        //     deep: true,
        //     immediate: true,
        //     handler: function () {
        //         this.navIndex = this.$route.query.type || 0
        //     }
        // }
    },
    methods: {
        getNav() {
            this.$post({
                url: "/api/New_Api/noticeCate"
            }).then(res => {
                this.navList = [{
                    name: "首页",
                },
                ...res,
                {
                    name: "联系我们",
                }]
            }).catch((err) => {
            });
        },
        clickNav(item) {
            this.$store.commit("getName", item.name)
            if (item.name == "首页") return this.$router.push("/")
            if (item.name == "联系我们") return this.$router.push("/aboutus")
            if (item.id) {
                this.$router.push({
                    path: "/annoncement",
                })
            }
        },
        clickTag(item) {
            this.tagName = item
        },
        login() {
            this.$router.push('/login')
        },
        clickSearch() {
            this.$router.push({
                path: "/search",
                query: {
                    id: this.tagName,
                    keyword: this.search
                }
            })
        }
    },
    computed: {
        ...mapState({
            navName: (state) => state.nav.name
        })
    },
    mounted() {
        this.token = localStorage.getItem("token")
        this.getNav()
    }
}
</script>

<style lang="scss" scoped>
.page-1 {
    background-color: #fff;
}

.box {
    width: 100%;
    height: 63px;
    background: #000e34;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .content {
        width: 1240px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            // color: white;
            font-size: 24px;
            line-height: 120%;
            width: 296px;
            height: 50%;
            background-image: url("../../assets/logo2.png");
            background-repeat: no-repeat;
            background-size: 80%;
            background-position: 0px;
            flex-shrink: 0;
        }

        .nav {
            width: 100%;
            height: 100%;
            margin: 0 130px 0 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;

            .item {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                cursor: pointer;
                user-select: none;
            }

            .active {
                :last-child {
                    position: absolute;
                    bottom: 3px;
                    width: 100%;
                    height: 2px;
                    background: #ffffff;
                }
            }
        }

        .login {
            width: 141px;
            height: 34px;
            background: #3366fe;
            border-radius: 2px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            cursor: pointer;
            user-select: none;
        }
    }
}

.bj {
    width: 1920px;
    height: 517px;
    margin: 0 auto;
    background-image: url("../../assets/bg3.jpg");
    background-size: 100% 100%;
    display: table;
    contain: "";

    .search_box {
        width: 932px;
        margin: 120px auto;
        border-radius: 10px;

        .tag {
            display: flex;
            align-items: center;
            gap: 0 42px;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #fff;
            cursor: pointer;
            user-select: none;

            .active {
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;

                :first-child {
                    color: #2056ff;
                    padding: 6px 11px;
                    background: #ffffff;
                    border-radius: 2px;
                }

                :last-child {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -16px;
                    width: 0;
                    height: 0;
                    border: #ffffff solid 8px;
                    border-right-color: transparent;
                    border-left-color: transparent;
                    border-bottom-color: transparent;
                }
            }
        }
    }

    .search {
        width: 932px;
        height: 58px;
        background: #ffffff;
        border-radius: 10px;
        margin-top: 21px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px 0 20px;
        box-sizing: border-box;

        img {
            width: 18px;
            height: 16px;
            flex-shrink: 0;
        }

        ::v-deep .input {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: none;

            input {
                width: 100%;
                height: 100%;
                border: none;
                margin: none;
            }
        }

        .btn {
            width: 109px;
            height: 43px;
            background: #3366fe;
            border-radius: 4px;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
        }
    }
}
</style>